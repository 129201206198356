import { useState } from 'react';

import CookieBannerGC from '@skyscanner-internal/global-components/cookie-banner';
import logger from 'saddlebag-logger';

import withErrorBoundary from '../ErrorBoundary/WithErrorBoundary';

import type { CookieBannerSSRProps } from '../../../../packages/common-types/types/CookieBanner';

const CookieBanner = (props: CookieBannerSSRProps) => {
  const [cookieBannerAccepted, setCookieBannerAccepted] = useState(false);
  const cookieBannerGCProps = {
    ...props,
    onPolicyAcceptClick: () => setCookieBannerAccepted(true),
  };

  if (typeof window !== 'undefined') {
    cookieBannerGCProps.logger = logger;
  }

  if (cookieBannerAccepted) {
    return null;
  }

  return <CookieBannerGC {...cookieBannerGCProps} />;
};

const CookieBannerWithErrorBoundary = withErrorBoundary(
  CookieBanner,
  'cookie-banner',
);

export default CookieBannerWithErrorBoundary;
